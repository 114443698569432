<template>
    <CompContent title="机构在线指标">
        <template #operate>
            <Button type="info" v-hasPermi="['ORG_ONLINE_CONTRACT:REFRESH']" @click="onUpdate">更新数据</Button>
            <Button type="primary" v-hasPermi="['ORG_ONLINE_CONTRACT:DOWNLOAD']" @click="onExport">导出</Button>
        </template>
        <LiefengTable class="statistics-orgonlineindex-table" :talbeColumns="columns" :tableData="tableData" hidePage :fixTable="true" style="margin-top:20px"></LiefengTable>
    </CompContent>
</template>

<script>
import CompContent from "../../customform/components/CompContent.vue"
import LiefengTable from "@/components/LiefengTable.vue"
import Request from "../../jointly/utils/request"

import Tip from "./components/Tip.vue"

export default {
    components: { CompContent, LiefengTable },

    data() {
        const date = new Date()
        // 前一天
        date.setDate(date.getDate() - 1)

        return {
            date: this.$core.formatDate(date, "yyyy-MM-dd"),
            columns: [],
            tableData: [],
        }
    },

    created() {
        this.getTableData()
    },

    methods: {
        /**
         * 获取表格数据
         */
        getTableData() {
            Request.get("/gateway/api/symanage/pc/orgOnlineIndex/getReport?statDate=" + this.date).then(res => {
                const columns = [
                    {
                        title: "序号",
                        align: "center",
                        width: 70,
                        type: "index",
                        fixed: "left",
                    },
                ]

                const group = {}

                // 表头分组
                res.header?.forEach(v => {
                    if (!group[v.group]) {
                        group[v.group] = []
                    }

                    group[v.group].push(v)
                })

                Object.keys(group).forEach(key => {
                    columns.push({
                        align: "center",
                        fixed: key === "org" ? "left" : "",
                        renderHeader: h => {
                            const item = group[key]?.[0]

                            if (!item || !item.groupName) return h("span", " ")

                            const doms = [h("span", item.groupName || " ")]

                            if (item.groupDesc) {
                                doms.push(
                                    h(Tip, {
                                        style: {
                                            marginLeft: "5px",
                                        },
                                        props: {
                                            content: item.groupDesc,
                                        },
                                    })
                                )
                            }

                            return h("div", {}, doms)
                        },
                        children: group[key]?.map(v => {
                            return {
                                title: v.headName,
                                align: "center",
                                width: key === "org" ? 100 : 80,
                                fixed: key === "org" ? "left" : "",
                                render: (h, params) => {
                                    const item = params.row.find(r => r.field === v.head)

                                    if (!item) return h("span", "")

                                    return h(
                                        "div",
                                        {
                                            style: {
                                                color: item.color,
                                                backgroundColor: item.bgColor,
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            },
                                        },
                                        item.fieldValue
                                    )
                                },
                            }
                        }),
                    })
                })

                this.columns = columns
                this.tableData = res.table
            })
        },

        /**
         * 更新数据
         */
        onUpdate() {
            Request.get("/gateway/api/symanage/pc/orgOnlineIndex/refresh").then(res => {
                this.$Message.success("更新成功")
                // 延迟 1 秒刷新数据
                setTimeout(() => {
                    this.getTableData()
                }, 1000)
            })
        },

        /**
         * 导出数据
         */
        onExport() {
            Request.get("/gateway/api/symanage/pc/orgOnlineIndex/download?statDate=" + this.date).then(url => {
                var a = document.createElement("a")
                var event = new MouseEvent("click")
                a.download = `机构在线指标 - ${this.date}`
                a.href = url
                a.dispatchEvent(event)
            })
        },
    },
}
</script>
<style lang="less">
.statistics-orgonlineindex-table {
    td {
        position: relative;
    }

    .ivu-table-cell {
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>
